import React from "react";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';



class JSONEditor extends React.Component {

set1EditorRef = instance => this.editor1 = instance;


 update(){
    console.log("updating")
    this.editor1.jsonEditor.set(this.props.textArea);
 }

 render() {
    return (
     <Editor ref={this.set1EditorRef}
                onChange={this.props.payLoadChanged}
                value= {this.props.textArea}
                mode='text'
                htmlElementProps={{style: {height: 800}}}
                search={true}
            />

    );}}

export { JSONEditor };
