import React from "react";
import {Button, Icon, Menu, MenuItem} from "@nike/eds";
import {Constants} from "../Constants";
import {CaretDown, CaretUp} from "@nike/nike-design-system-icons";
import {FetchService} from "./FetchService";


class TopicSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            topics: ["loading..."]
        };
    }


    componentDidMount() {
        let url = Constants.APIURL+ "/kafka/topics";
        FetchService(url, "GET", "").then(response => {response.json().then(json => {this.setState({topics: json})})
            });
    }

    setOpen(open) {
        this.setState({"open": open})
    }

    render() {
        return (
            <Menu
                isDark={true}
                onClickOutside={() => {
                    this.setOpen(!this.state.open);
                }}
                isOpen={this.state.open}
                bodySlot={
                    (() => {
                        const filteredTopics = this.state.topics
                            .filter(topic => (this.props.selectedEnvironment === null || topic.nikeEnvironment === this.props.selectedEnvironment))
                            .filter(topic => (this.props.selectedFlow === null || topic.flow === this.props.selectedFlow));

                        if (filteredTopics.length === 0) {
                            return (
                                <div class="eds-menu-item">No topics available.</div>
                            );
                        }

                        return filteredTopics.map(topic => (
                            <MenuItem
                                key={topic.name}
                                onClick={() => { this.setState({ 'topic': topic.name, 'containsNmkAvroSchema': topic.nmkAvroConfiguration != null }); this.props.onSelect(topic.name, topic.nmkAvroConfiguration != null); }}
                                beforeSlot={this.state.topic === topic ? <Icon name="Check" /> : <></>}
                            >
                                {topic.name}
                            </MenuItem>
                        ));
                    })()
                }
                placement={"bottom-start"}
            >
                <Button
                    beforeSlot={this.state.open ? <CaretUp /> : <CaretDown />}
                    variant="secondary"
                    onClick={() => this.setOpen(!this.state.open)}>
                    {this.state.topic ? this.state.topic : "Select topic"}
                </Button>
            </Menu>
        )

    }

}

export { TopicSelector };
