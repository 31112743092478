import React from "react";
import {Constants} from "../Constants";
import {AppHeader, Button} from "@nike/eds/dist";
import {EventSelector} from "./EventSelector";
import {TopicSelector} from "./TopicSelector";
import {JSONEditor} from "./JSONEditor";
import {NikeApp, Play} from "@nike/nike-design-system-icons";
import {Card} from "@nike/eds";
import {Snack, Snackbar} from "@nike/eds";
import {Spinner} from "@nike/eds";
import {FetchService} from "./FetchService";
import {Radio, RadioGroup} from "@nike/eds";
import {ButtonGroup} from "@nike/eds";
import {Label} from "@nike/eds";

function Header() {

    return (
        <AppHeader
            isDark
            appName="Logistics Platform - Ghostbuster Test UI"
            logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"}/>}
        />
    );
}


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.jsonEditor = React.createRef()
        this.keyJsonEditor = React.createRef()
        this.state = {
            event: null,
            topic: null,
            textArea: JSON.parse('{"Select the desired payload format or insert your custom payload": "0"}'),
            keyTextArea: JSON.parse('{}'),
            showSnackSuccess: false,
            showSnackFailure: false,
            snackBarText: "",
            loading: false,
            selectedEnvironment: null,
            selectedFlow: null,
            containsNmkAvroSchema: false
        };

        // this.selectMessageFlow = this.selectMessageFlow.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
        this.payloadChangedUpdateState = this.payloadChangedUpdateState.bind(this);
        this.keyChangedUpdateState = this.keyChangedUpdateState.bind(this);
        this.selectTopic = this.selectTopic.bind(this);

    }

    updateTextArea() {
        let url = Constants.APIURL + "/events/" + this.state.event.name;
        FetchService(url, "GET", "").then(response => {
            response.json().then(json => {
                this.setState({textArea: json})
                this.jsonEditor.current.update()

            })
        });

    }

    sendPayload() {
        let url = Constants.APIURL + "/kafka/publish";
        this.setState({loading: true});

        FetchService(url, "POST", this.getPayload())
            .then(r => {
                if (r.ok) {
                    this.setState({
                        "snackBarText": "Success",
                        "loading": false,
                        "showSnackSuccess": true
                    });
                } else {
                    this.setState({
                        "snackBarText": "Error: " + r.status + " " + r.statusText,
                        "loading": false,
                        "showSnackFailure": true
                    });
                }
            })
    }

    getPayload() {
        let payload = {};
        payload.payload = JSON.stringify(this.state.textArea);
        payload.topicName = this.state.topic;
        payload.key = JSON.stringify(this.state.keyTextArea)
        return JSON.stringify(payload);
    }

    selectEvent(event) {
        this.setState({
            event
        }, function () {
            this.updateTextArea()
        });
    }

    selectTopic(topic, containsNmkAvroSchema) {
        this.setState({
            topic,
            containsNmkAvroSchema
        })
    }

    selectEnvironment(environment) {
        this.setState({
            selectedEnvironment: environment
        })
    }

    selectFlow(flow) {
        this.setState({
            selectedFlow: flow
        })
    }

    selectShippingPoint(shippingPoint) {
        this.setState({
            selectedShippingPoint: shippingPoint
        })
    }

    payloadChangedUpdateState(e) {
        this.setState({
            textArea: e
        });
    }

    keyChangedUpdateState(e) {
        this.setState({
            keyTextArea: e
        });
    }


    clearFilters() {
        this.setState({
            selectedEnvironment: null,
            selectedFlow: null,
        })
    }

    render() {
        return (
            <div>
                <Header/>
                <div>
                    <Card className="eds-grid eds-grid--cols-4">
                        <div className="eds-card eds-elevation--4 eds-grid--col-full">
                            <div className="eds-grid eds-grid--cols-4">
                                <div
                                    className="eds-card eds-elevation--1 eds-grid eds-grid--m-cols-3 eds-grid--m-col-2">
                                    <RadioGroup className="eds-grid--m-col-1"
                                                label="Environment"
                                                name="environment-radio-button-group"
                                                valueSelected={this.state.selectedEnvironment}
                                                onChange={e => this.selectEnvironment(e.target.value)}
                                    >
                                        <Radio label="DEV" value="dev" id="env-radio-1"/>
                                        <Radio label="TEST" value="test" id="env-radio-2"/>
                                        <Radio label="VPT" value="VPT" id="env-radio-3"/>
                                        <Radio label="QA" value="QA" id="env-radio-4"/>
                                    </RadioGroup>
                                    <RadioGroup className="eds-grid--m-col-1"
                                                label="Flow"
                                                name="flow-radio-button-group"
                                                valueSelected={this.state.selectedFlow}
                                                onChange={e => this.selectFlow(e.target.value)}
                                    >
                                        <Radio label="BlueYonder (1065)" value="blueyonder"
                                               id="component-radio-1"/>
                                        <Radio label="MATM (1067)" value="matm"
                                               id="component-radio-2"/>
                                        <Radio label="Legacy MATM (1067)" value="legacy-matm"
                                               id="component-radio-3"/>
                                        <Radio label="MATM shipment plan closed (1067)"
                                               value="matm-shipment-plan-closed"
                                               id="component-radio-4"/>
                                    </RadioGroup>
                                    <ButtonGroup>
                                        <Label class="eds-type--title-6">Selection</Label>
                                        <TopicSelector
                                            selectedEnvironment={this.state.selectedEnvironment}
                                            selectedFlow={this.state.selectedFlow}
                                            onSelect={this.selectTopic}
                                        />
                                        <EventSelector selectedFlow={this.state.selectedFlow} flow={this.state.event}
                                                       onSelect={this.selectEvent}/>
                                        <Button onClick={() => {
                                            this.clearFilters()
                                        }}>
                                            Clear filters
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="eds-grid--col-full">
                        <Card className="eds-card eds-elevation--4 eds-grid--col-full">
                            {
                                this.state.containsNmkAvroSchema &&
                                <div>
                                    <p>Key: </p>
                                    <p><i>Add the avro key of the event</i></p>
                                    <JSONEditor payLoadChanged={this.keyChangedUpdateState} ref={this.keyJsonEditor}
                                                textArea={this.state.keyTextArea}/>
                                </div>
                            }
                            <br/>
                            Event:
                            <JSONEditor payLoadChanged={this.payloadChangedUpdateState} ref={this.jsonEditor}
                                        textArea={this.state.textArea}/>
                            <br/>
                            <Button beforeSlot={this.state.loading ? <></> : <Play></Play>} size="medium"
                                    disabled={this.state.loading} variant="primary" onClick={() => {
                                this.sendPayload()
                            }}>
                                {this.state.loading ? <Spinner size="medium"/> : "Submit payload"}
                            </Button>

                            <Snackbar>
                                {this.state.showSnackSuccess && (
                                    <Snack id="success" status="success"
                                           onDismiss={id => this.setState({"showSnackSuccess": false})}
                                           autoDismissDuration={5000}>
                                        {this.state.snackBarText}
                                    </Snack>
                                )}`
                                {this.state.showSnackFailure && (
                                    <Snack className="eds-snack-error" id="error" status="error"
                                           onDismiss={id => this.setState({"showSnackFailure": false})}
                                           autoDismissDuration={5000}>
                                        {this.state.snackBarText}
                                    </Snack>
                                )}
                            </Snackbar>
                        </Card>
                    </Card>
                </div>
            </div>
        );
    }

}

export {Home};
